import React from 'react'
import { I18nextContext, Trans } from 'gatsby-plugin-react-i18next'
import moment from 'moment'
import 'moment/locale/ru'
import 'moment/locale/ja'
import BackIcon from './assets/images/back.svg'
import * as styles from './assets/css/blog-post.module.css'
import { Button, Container, Heading } from '../components/common'

const BlogPost = ({ pageContext }) => {
  const { post } = pageContext
  const context = React.useContext(I18nextContext);
  const currentLanguage = context.language
  moment.locale(currentLanguage)
  return (
    <section className={styles.root}>
      <header className={styles.header}>
        <div className={styles.bg}>
          <img
            className={styles.featured}
            src={post.frontmatter.featuredImage}
            alt={post.frontmatter.title}
          />
          <div className={styles.overlay}></div>
          <Container className={styles.grid}>
            <Button el='a' className={styles.button} href={'/#blog'}>
              <BackIcon />
              <span><Trans>Назад</Trans></span>
            </Button>
            <div className={styles.delimiter}></div>
            <div className={styles.date}>
              {moment(post.frontmatter.date).format('D MMMM, YYYY')}
            </div>
            <Heading className={styles.heading}>
              {post.frontmatter.title}
            </Heading>
          </Container>
        </div>
      </header>
      <div className={styles.content}>
        <div className={styles.layout} style={{backgroundImage: `url(/svg/pattern.svg)`}}></div>
        <Container>
          <div className={styles.wrapper}>
            <div
              dangerouslySetInnerHTML={{ __html: post.html }}
              className={styles.html}
            />
          </div>
        </Container>
      </div>
    </section>
  )
}

export default BlogPost