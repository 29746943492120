// extracted by mini-css-extract-plugin
export var root = "b_b";
export var header = "b_c";
export var bg = "b_d";
export var featured = "b_f";
export var scale = "b_g";
export var overlay = "b_h";
export var grid = "b_j";
export var button = "b_k";
export var delimiter = "b_l";
export var date = "b_m";
export var heading = "b_n";
export var content = "b_p";
export var layout = "b_q";
export var wrapper = "b_r";
export var html = "b_s";